<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 mx-auto">
        <Form type="edit" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import Form from "@/views/pages/categories/form";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + ' | ' + this.$t("category.edit"),
    };
  },
  components: {
    Layout,
    PageHeader,
    Form,
  },
  data() {
    return {
      title: "category.edit",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.categories",
          to: "/categories",
        },
        {
          tran: "category.edit",
          active: true,
        },
      ],
    };
  },
};
</script>
